jQuery(document).ready(function($) {

  console.log('ready');

  // // $('.nav-primary .parent .a1').fastClick(function(){
  $('.touch-menu .nav-primary .parent .a1').fastClick(function(e){
    e.preventDefault();
    e.stopPropagation();
    $(this).parent().toggleClass('open');
  });

  // $('.touch-menu-toggle').fastClick(function(){
  $('.touch-menu-toggle').fastClick(function(e){
    $('.touch-menu').addClass('open');
  });

  // // $('.touch-menu-close').fastClick(function(){
  $('.touch-menu-close').fastClick(function(e){
    $('.touch-menu').removeClass('open');
  });

  //Remove open class from touch menu to accomodate back button.
  $('.a1').click(function(e){
    $('.touch-menu').removeClass('open');
  });

  var owl = $('.carousel');

  // owl.owlCarousel({
  //   margin: 58,
  //   loop: false,
  //   autoWidth: true,
  //   center: true,
  //   startPosition: 2
  // });

  // $('.btn-next').click(function() {
  //     owl.trigger('next.owl.carousel', [300]);
  // });

  // $('.btn-prev').click(function() {
  //     owl.trigger('prev.owl.carousel', [300]);
  // });

  $('h2:empty').remove();

});

// SEARCH TOGGLE START
$('.search-btn').click(function (e) {
    e.stopPropagation();
    $('.search-box-container').slideToggle("fast");
  });
  $('.search-close').click(function () {
    $('.search-box-container').slideToggle("fast");
  });
    // $(document).click(function () {
    //     var search = $(".search-box-container");
    //     if (search.is(":visible")) {
    //         search.slideUp("fast");
    //     }
    // });
// SEARCH TOGGLE END

////SEARCH FUNCTIONALITY START
//$(document).ready(function () {
//  $('.search-box button').on('click', function () {
//    var query = $('.search-box input').val();
//    window.location.href = "/Search/?q=" + query;
//    return false;
//  });

//  $('.search-box input').keypress(function (e) {
//    if (e.which == 13) {
//      var query = $('.search-box input').val();
//      window.location.href = "/Search/?q=" + query;
//      return false;
//    }
//  });
//});
////SEARCH FUNCTIONALITY END

//Parallax subpage JS
$(".photo").last().css("min-height", "0");
$(".photo-content").last().css({"margin-bottom": "-10px", "bottom": "0"});
$(".photo-content").first().css("padding-top", "220px");
//END Parallax subpage JS

//Dick's Bait and Tackle subpage JS
// $(".fuel-dock-ships-store .photo-content").first().css("padding-top", "350px");
//END Dick's Bait and Tackle subpage JS

//Smooth Scrolling for Anchor Tags
$('a[href^="#"]:not([href=#])').click(function () {
  $('html, body').animate({
      scrollTop: $('[id="' + $.attr(this, 'href').substr(1) + '"]').offset().top-30
  }, 2500);

  return false;
});
//Smooth Scrolling for Anchor Tags END

//Prevent rough scrolling in IE11
if(navigator.userAgent.match(/Trident\/7\./)) {
  $('body').on("mousewheel", function () {
      event.preventDefault();
      var wd = event.wheelDelta;
      var csp = window.pageYOffset;
      window.scrollTo(0, csp - wd);
  });
}
//Prevent rough scrolling in IE11 END

//Random Homepage Background Image
var random = Math.floor(Math.random() * $('.homepage-background img').length);
$('.homepage-background img').hide().eq(random).show();
//Random Homepage Background Image END

//Hide Subpage Background if No Featured Image
$(".subpage-background").not(':has("img")').hide();
//END

// Accordions
$('.accordions h3').each(function () {
  var $this = $(this);

  // create unique id for a11y relationship
  var id = 'accordion-' + $this.index();

  // wrap the content and make it focusable
  $this.nextUntil('h3').wrapAll('<div id="' + id + '" aria-hidden="true">');
  var panel = $this.next();

  // Add the button inside the <h2> so both the heading and button semanics are read
  $this.wrapInner('<button aria-expanded="false" aria-controls="' + id + '">');
  var button = $this.children('button');

  // Toggle the state properties
  button.on('click', function (e) {
    e.preventDefault();
    var state = $(this).attr('aria-expanded') === 'false' ? true : false;
    $(this).attr('aria-expanded', state);
    $(this).parent('h3').toggleClass('is-active');
    panel.attr('aria-hidden', !state);
  });
});

// Tabs
// The class for the container div
var tabContainer = '.tabs';

// The setup
$(tabContainer + ' ul').attr('role', 'tablist').empty();
// $(tabContainer +' [role="tablist"]').append('role','presentation');
$(tabContainer + ' .tab-content').each(function () {
  var tabHeading = $(this).find(">:first-child").text();
  var tabHeadingID = $(this).find(">:first-child").attr('id');
  $(tabContainer + ' ul').append('<li><a href="#' + tabHeadingID + '">' + tabHeading + '</a></li>');
});
$(tabContainer + ' [role="tablist"] li').attr('role', 'presentation');
$('[role="tablist"] a').attr({
  'role': 'tab',
  'tabindex': '-1'
});

// Make each aria-controls correspond id of targeted section (re href)
$('[role="tablist"] a').each(function () {
  $(this).attr(
    'aria-controls', $(this).attr('href').substring(1)
  );
});

// Make the first tab selected by default and allow it focus
$('[role="tablist"] li:first-child').addClass('active');
$('[role="tablist"] li:first-child a').attr({
  'aria-selected': 'true',
  'tabindex': '0'
});

// Make each section focusable and give it the tabpanel role
$(tabContainer + ' section').attr({
  'role': 'tabpanel'
});

// Make first child of each panel focusable programmatically
$(tabContainer + ' section > *:first-child').attr({
  'tabindex': '0'
});

// Make all but the first section hidden (ARIA state and display CSS)
$('[role="tabpanel"]:not(:first-of-type)').attr({
  'aria-hidden': 'true'
});

// Change focus between tabs with arrow keys
$('[role="tab"]').on('keydown', function (e) {

  // define current, previous and next (possible) tabs
  var tabCurrent = $(this);
  var tabPrev = $(this).parents('li').prev().children('[role="tab"]');
  var tabNext = $(this).parents('li').next().children('[role="tab"]');
  var tabTarget;

  // find the direction (prev or next)
  switch (e.keyCode) {
    case 37:
      tabTarget = tabPrev;
      break;
    case 39:
      tabTarget = tabNext;
      break;
    default:
      tabTarget = false;
      break;
  }

  if (tabTarget.length) {
    $('.tab-list li').removeClass('active');
    tabCurrent.parents('li').addClass('active');
    tabCurrent.attr({
      'tabindex': '-1',
      'aria-selected': null
    });
    tabTarget.attr({
      'tabindex': '0',
      'aria-selected': true
    }).focus();
  }

  // Hide panels
  $(tabContainer + ' [role="tabpanel"]')
    .attr('aria-hidden', 'true');

  // Show panel which corresponds to target
  $('#' + $(document.activeElement).attr('href').substring(1))
    .attr('aria-hidden', null);
});

// Handle click on tab to show + focus tabpanel
$('[role="tab"]').on('click', function (e) {
  e.preventDefault();

  // remove focusability [sic] and aria-selected
  $('.tab-list li').removeClass('active');
  $('[role="tab"]').attr({
    'tabindex': '-1',
    'aria-selected': null
  });

  // replace above on clicked tab
  $(this).parents('li').addClass('active');
  $(this).attr({
    'aria-selected': true,
    'tabindex': '0'
  });

  // Hide panels
  $(tabContainer + ' [role="tabpanel"]').attr('aria-hidden', 'true');

  // show corresponding panel
  $('#' + $(this).attr('href').substring(1))
    .attr('aria-hidden', null);

});

